const loadDisableButtons = () => {
  let buttons = document.querySelectorAll(".load-disable-button")

  for(let button of buttons){
    button.addEventListener("click", function(){
      let textContent = this.textContent
      this.innerHTML = textContent + `<span class="spinner-border text-primary ml-1" id="" role="status" style="height: 15px; width: 15px;"></span>`
      setTimeout(() => {
        this.disabled = true; // Disable after a short delay to allow other click events to occur
      }, 20);
    })

  }
}

export { loadDisableButtons };
let buttons = document.querySelectorAll(".load-disable-button")