// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Import 3rd party librairies 
import "svg-injector"
require("theme/quick-website.js")
import "@fortawesome/fontawesome-free/js/all"
import tocbot from "tocbot"


// Import Bootstrap 
import "bootstrap"
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Import my own JS functions 
import { pricing } from '../theme/quick-website';
import { makeAlertAutofade } from '../components/_alert';
import { deportClick } from '../components/_deported_click';
import { copyButton } from '../components/_copy_button';
import { jsonPrettify } from '../components/_json_prettify';
import { readMore } from '../components/_read_more';
import { detailedModal } from '../components/_detailed_modal';
import { collapse } from '../components/_collapse';
import { createToc } from '../components/_create_toc';
import { ajaxSubmit } from '../components/_ajax_submit';
import { reinitializeTooltip } from '../components/_reinitialize_tooltip';
import { resetModal } from '../components/_reset_modal';
import { loadDisableButtons } from '../components/_load_disable_button';

// Make my own JS work 
document.addEventListener("turbolinks:load", function() {
  createToc();
  makeAlertAutofade();
  deportClick();
  copyButton();
  jsonPrettify();
  readMore();
  detailedModal();
  collapse();
  pricing();
  ajaxSubmit();
  reinitializeTooltip();
  resetModal();
  loadDisableButtons();

  tocbot.refresh();
})



