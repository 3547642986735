const resetModal = () => {
  let modalButtons = document.querySelectorAll('[data-toggle="modal"]')

  for(let button of modalButtons){
    button.addEventListener("click", function(){
      let modalId = button.dataset?.target
      let modalElement = document.querySelector(modalId)
      if(!modalElement){return}
  
      let elementsToHide = modalElement.querySelectorAll(".modal-reset-hide")
      for(let element of elementsToHide){
        element.style.setProperty('display', 'none', 'important');
      }

      let elementsToShow = modalElement.querySelectorAll(".modal-reset-show")
      for(let element of elementsToShow){
        element.style.display = ""
      }

      let elementsToEmpty = modalElement.querySelectorAll(".modal-reset-empty")
      for(let element of elementsToEmpty){
        element.textContent = ""
      }
    })
  }
}



export { resetModal };